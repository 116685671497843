<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考评员管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.adminId ? '编辑' : '新增' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" class="ruleForm" :rules="rules" :model="ruleForm" label-width="100px" size="small"
              style="width: 500px;">
              <div class="fromTitle">账号信息</div>
              <el-form-item label="账号名称" prop="adminName">
                <el-input type="text" v-model="ruleForm.adminName" clearable placeholder="请输入账号名称" />
              </el-form-item>
              <el-form-item label="姓名" prop="fullname">
                <el-input type="text" v-model="ruleForm.fullname" clearable placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item label="联系方式" prop="mobile">
                <el-input v-model="ruleForm.mobile" placeholder="请输入联系方式"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model="ruleForm.idCard" placeholder="请输入身份证号"></el-input>
              </el-form-item>
              <el-form-item label="作业类别:" prop="workCategory">
                <el-select size="small" v-model="ruleForm.workCategory" clearable placeholder="请选择作业类别">
                  <el-option v-for="item in workCategoryList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属考点" prop="centerId">
                <el-select clearable size="small" v-model="ruleForm.centerId" placeholder="请选择">
                  <el-option v-for="item in centerIdList" :key="item.centerId" :label="item.centerName"
                    :value="item.centerId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button class="bgc-bv" @click="$router.back()">取 消</el-button>
                <el-button class="bgc-bv" @click="sureData">确 定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "systemManage_examinerManage_addAndedit",
  data() {
    return {
      // 上个页面的数据
      lastPageData: {
        adminId: "", // 账号id
      },
      // 基本信息
      ruleForm: {
        adminName: "", // 账号名称
        fullname: "", // 姓名
        idCard: "", // 身份证号
        mobile: "", // 联系方式
        centerId: "", // 考点id
      },
      // 基本信息 - 数据校验
      rules: {
        adminName: [
          { required: true, trigger: "blur", message: "请输入账号名称" }
        ],
        fullname: [
          { required: true, trigger: "blur", message: "请输入姓名" }
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" }
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: 'blur' }
        ],
        workCategory: [
          { required: true, trigger: "change", message: "请选择作业类别" }
        ],
        centerId: [
          { required: true, trigger: "change", message: "请选择考点" }
        ],
      },
      workCategoryList: [], // 作业类别 - 下拉数据
      centerIdList: [], // 所属考点 - 下拉数据
    };
  },
  created() {
    this.lastPageData.adminId = this.$route.query?.adminId ?? "";
    this.getDictionary();
    this.getCenterIdList();
    if (this.lastPageData.adminId) {
      this.getDetail();
    }
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 作业类别
      let workCategory = this.$setDictionary("PA_WORK_CATEGORY", "list");
      for (const key in workCategory) {
        this.workCategoryList.push({
          value: key,
          label: workCategory[key],
        });
      }
    },
    // 获取 - 所属考点 - 下拉数据
    getCenterIdList() {
      this.$post("/biz/center/pa-test-center/centerList", {}, 3000, true, 10).then((res) => {
        this.centerIdList = res.data || [];
        // 如过只有一个考点默认选中第一个
        if (res.data.length == 1) {
          this.ruleForm.centerId = res.data[0].centerId;
        }
      });
    },
    // 获取 - 详情
    getDetail() {
      this.$post("/biz/affiliation/pa-examiner-affiliation/getByAdminId", {
        adminId: this.lastPageData.adminId
      }, 3000, true, 10).then((res) => {
        this.ruleForm = { ...this.ruleForm, ...res.data }
      });
    },
    // 保存 - 新增&编辑
    sureData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let cData = {
            ...this.ruleForm,
          }
          let url = '';
          if (this.lastPageData.adminId) {
            url = '/biz/affiliation/pa-examiner-affiliation/update'
          } else {
            url = '/biz/affiliation/pa-examiner-affiliation/insert'
          }
          this.$post(url, cData, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功')
              this.$router.back();
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.ruleForm {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>